import { Box, Flex, Button } from 'packages/uikit'
import styled from 'styled-components'

export const StyledSwapContainer = styled(Flex)<{ $isChartExpanded: boolean }>`
  flex-shrink: 0;
  height: fit-content;

  ${({ theme }) => theme.mediaQueries.sm} {
    padding: 0 10px;
  }

  ${({ theme }) => theme.mediaQueries.md} {
    padding: 0 24px;
  }

  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 0 40px;
  }

  ${({ theme }) => theme.mediaQueries.xxl} {
    ${({ $isChartExpanded }) => ($isChartExpanded ? 'padding: 0 120px' : 'padding: 0 40px')};
  }
`

export const StyledInputCurrencyWrapper = styled(Box)``

export const StyledAbstradexButton = styled(Button)`
  color: ${({ theme }) => theme.colors.absWhiteColor};
  background: ${({ theme }) => theme.colors.absMainColor};
  border-radius: 8px;
  min-height: 29px;

  ${({ theme }) => theme.mediaQueries.md} {
    min-height: 61px;
  }
`
