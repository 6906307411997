import { Percent } from '@abstra-dex/sdk'
import { warningSeverity } from 'utils/prices'
import { ONE_BIPS } from '../../../config/constants'
import { ErrorText } from './styleds'

/**
 * Formatted version of price impact text with warning colors
 */
export default function FormattedPriceImpact({ priceImpact, isCard }: { priceImpact?: Percent; isCard: boolean }) {
  return (
    <ErrorText
      fontSize="14px"
      severity={warningSeverity(priceImpact)}
      style={{
        color: isCard ? '#000' : '#fff',
      }}
    >
      {priceImpact ? (priceImpact.lessThan(ONE_BIPS) ? '<0.01%' : `${priceImpact.toFixed(2)}%`) : '-'}
    </ErrorText>
  )
}
