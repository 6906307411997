import styled from 'styled-components'
import {
  // ChartIcon,
  Flex,
  Heading,
  HistoryIcon,
  IconButton,
  NotificationDot,
  Text,
  useModal,
  // ChartDisableIcon,
} from 'packages/uikit'
import TransactionsModal from 'components/App/Transactions/TransactionsModal'
import GlobalSettings from 'components/Menu/GlobalSettings'
import { useExpertModeManager } from 'state/user/hooks'
import RefreshIcon from 'components/Svg/RefreshIcon'

interface Props {
  title: string
  subtitle: string
  noConfig?: boolean
  setIsChartDisplayed?: React.Dispatch<React.SetStateAction<boolean>>
  isChartDisplayed?: boolean
  hasAmount: boolean
  onRefreshPrice: () => void
}

const CurrencyInputContainer = styled(Flex)`
  flex-direction: column;
  align-items: center;
  width: 100%;

  ${({ theme }) => theme.mediaQueries.xs} {
    padding: 10px;
  }
  ${({ theme }) => theme.mediaQueries.lg} {
    padding: 36px 36px 10px 36px;
  }
`

const StyledHeading = styled(Heading)`
  font-weight: 600;
  font-size: clamp(20px, 4vw, 32px);
`

const StyledText = styled(Text)`
  font-size: clamp(12px, 2vw, 18px);
`

// const ColoredIconButton = styled(IconButton)`
//   color: ${({ theme }) => theme.colors.textScroll};
// `

const CurrencyInputHeader: React.FC<Props> = ({
  title,
  subtitle,
  setIsChartDisplayed,
  isChartDisplayed,
  hasAmount,
  onRefreshPrice,
}) => {
  const [expertMode] = useExpertModeManager()
  const toggleChartDisplayed = () => {
    setIsChartDisplayed((currentIsChartDisplayed) => !currentIsChartDisplayed)
  }
  const [onPresentTransactionsModal] = useModal(<TransactionsModal />)

  return (
    <CurrencyInputContainer>
      <Flex width="100%" alignItems="center" justifyContent="space-between">
        <Flex flexDirection="column" alignItems="flex-start" width="100%" mr={18}>
          <StyledHeading as="h2" color="absBlackColor">
            {title}
          </StyledHeading>
          <StyledText color="absBlackColor">{subtitle}</StyledText>
        </Flex>
        <Flex alignSelf="self-start" style={{ gap: 0 }}>
          {/* {setIsChartDisplayed && (
            <ColoredIconButton onClick={toggleChartDisplayed} variant="text" scale="sm">
              {isChartDisplayed ? <ChartDisableIcon color="#ff6107" /> : <ChartIcon width="24px" color="#ff6107" />}
            </ColoredIconButton>
          )} */}
          <NotificationDot show={expertMode}>
            <GlobalSettings color="absBlackColor" mr="0" />
          </NotificationDot>
          <IconButton onClick={onPresentTransactionsModal} variant="text" scale="sm">
            <HistoryIcon color="absBlackColor" width="24px" />
          </IconButton>
          <IconButton variant="text" scale="sm" onClick={() => onRefreshPrice()}>
            <RefreshIcon disabled={!hasAmount} width="28px" />
          </IconButton>
        </Flex>
      </Flex>
    </CurrencyInputContainer>
  )
}

export default CurrencyInputHeader
